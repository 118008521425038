import React, {createContext, useReducer} from 'react';
import registerReducer from './constants/registerReducer';

import initialState from './constants/InitialState';

const Store=({children}) => {

	const [state, dispatch] = useReducer(registerReducer, initialState);

    return (
            <Context.Provider value={[state, dispatch]}>
                {children}
            </Context.Provider>
    )
}


export const Context = createContext(initialState);

export default Store;