import React, { useState, useEffect, useContext } from "react";
import { useWeb3React } from "@web3-react/core";
import { Button } from "antd";
import { metaMask } from "../../connectors/metaMask";
import { walletConnect } from "../../connectors/walletConnect";
import { getEllipsisTxt } from "../../utils/formatters";
import Jazzicons from "../Jazzicons";
import ConnectModal from "./ConnectModal";
import DisconnectModal from "./DisconnectModal";
import { Context } from "./../../Store"; 
import ACTIONS from "../../constants/Actions";
import {MyFunction} from "../../func/Funcs"; 

const styles = {
  account: {
    height: "42px",
    padding: "0 15px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "fit-content",
    borderRadius: "7px",
    backgroundColor: "blue",
    cursor: "pointer",
    color: "white"
  },
  button: {
    height: "40px",
    padding: "0 20px",
    textAlign: "center",
    fontWeight: "600",
    letterSpacing: "0.2px",
    fontSize: "15px",
    margin: "20px 20px",
    border: "none",
    backgroundColor: "blue",
    color: "white"
  },
  text: {
    color: "white"
  },
  modalTitle: {
    marginBottom: "20px",
    padding: "10px",
    display: "flex",
    justifyContent: "center",
    fontWeight: "700",
    fontSize: "20px"
  }
}


const ConnectWallet = (props) => {
  const [state, dispatch] = useContext(Context);
  const { account, provider } = useWeb3React();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isAuthModalOpen, setIsAuthModalOpen] = useState(false);  
  const [isConnecting, setIsConnecting] = useState(false);


  useEffect(() => {

    
    if(account !== undefined ){
                      getBalance();

                      dispatch({type: ACTIONS.TOGGLE_IS_LOGGED_IN, payload: true});    
                      dispatch({type: ACTIONS.TOGGLE_TAB, payload: true});  
 
    }
    
   



  },[account]);


  const disconnect = async () => {
    const connector = metaMask || walletConnect;
    dispatch({type: ACTIONS.TOGGLE_IS_LOGGED_IN, payload: false});
    dispatch({type: ACTIONS.TOGGLE_ADMIN, payload: false});
    dispatch({type: ACTIONS.UPDATE_BALANCE, payload: 0});
     
    dispatch({type: ACTIONS.UPDATE_USER_ID, payload: 0});  
    
    
    setIsModalVisible(false);
    setIsAuthModalOpen(false);
    localStorage.removeItem("connectorId");
    if (connector.deactivate) {
      connector.deactivate();
    } else {
      connector.resetState();
    }
    // @ts-expect-error close can be returned by wallet
    if (connector && connector.close) {
      // @ts-expect-error close can be returned by wallet

    
      await connector.close();
    }
  };


  const getBalance = async () => {

    
    let metBalance = await MyFunction.balanceOf(account, provider, state.tokenContract);
 
    dispatch({type: ACTIONS.UPDATE_BALANCE, payload: metBalance});
    console.log(metBalance);
     


  }


  return (
    <>
      {account === undefined ? (

       <>
                   <div>
                      <ConnectModal isModalOpen={isAuthModalOpen} setIsModalOpen={setIsAuthModalOpen} />

                      <Button className="btn-primary"   style={styles.account} onClick={() => setIsAuthModalOpen(true)}>
                        <b style={{color: "white"}}>Connect Wallet</b>
                      </Button>

                      
                    </div>
           
       
       </>
        
      ) : (
        <>

            {
                isConnecting === true ? <>
                <Button className="btn-primary btn-dissabled" >
                  <p style={{color: "white"}}>Connecting wallet...</p>
                </Button >
                 
                </>:<>
                  
                      <div style={styles.account} onClick={() => setIsModalVisible(true)}>
                        {account && typeof account === "string" && (
                          <p style={{ marginRight: "5px", ...styles.text }}>{getEllipsisTxt(account, 6)}</p>
                        )}
                        <Jazzicons seed={account} />
                       
                      </div>

                      <DisconnectModal isModalOpen={isModalVisible} setIsModalOpen={setIsModalVisible} disconnect={disconnect} />
                      
                
                </>
              }
       


        </>
      )}
    </>
  );
};

export default ConnectWallet;
