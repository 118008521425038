import React, { useEffect, useState, useContext } from 'react'; 
import {useNavigate} from "react-router-dom";
import { ethers } from "ethers";
import contractAbi from "../../contract/GenericERC20.json";
import {Auth, Myalert} from "../../helpers"; 
import { Context } from '../../Store';
import ACTIONS from "../../constants/Actions";
import {MyFunction} from "../../func/Funcs";   
import { useWeb3React } from "@web3-react/core";  
import ConvertToken from './ConvertToken';



var numeral = require('numeral');



const Web2Login = () => {

  
  const navigate = useNavigate();
  const [state, dispatch] = useContext(Context); 
  //const { switchNetwork, chainId } = useChain();
  
  
  const userAddress = state.walletAddress;
   
  
  
  const [email, setEmail] = useState('');
  const [pass, setPass] = useState('');
   
  const[ ErrorMessage, setErrorMessage] = useState(""); 
  const [toggleAlert, setToggleAlert] = useState(false); 
  
  const [showButton, setShowButton] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);
  const [serverInfo, setServerInfo] = useState("");  
  const { account, provider } = useWeb3React();  
  const [showConvert, setShowConvert] = useState(false);  

 

async function updateBalance(){

  let newBal = await MyFunction.balanceOf(userAddress);
  dispatch({type: ACTIONS.UPDATE_BALANCE, payload: newBal});
  
}


const  pLogin = async () => {

   
  if(email.length < 5){
    setErrorMessage('Enter your dogetagold login email address');
    setToggleAlert(true);
    return false;
  }

  if(pass.length < 5){
    setErrorMessage('Enter your dogetagold login password');
    setToggleAlert(true);
    return false;
  }

 
  setServerInfo('connecting your web2 wallet');
  setToggleAlert(true);
  setIsProcessing(true);

  Auth.login (email, pass)
  .then(res => {
 
      if(res.data.error){
          setErrorMessage(res.data.message);
           
      }else{
         
         dispatch({type: ACTIONS.UPDATE_WEB2LOGIN, payload: true});
         dispatch({type: ACTIONS.UPDATE_WEB2BALANCE, payload: res.data.wallet_balance});
         dispatch({type: ACTIONS.UPDATE_USER_ID, payload: res.data.user_id});
         dispatch({type: ACTIONS.UPDATE_FULL_NAME, payload: res.data.fullname});
         dispatch({type: ACTIONS.UPDATE_WEB2_IS_VERIFY, payload: res.data.account_is_verify});
         
         setShowConvert(true);
         setToggleAlert(false);
      }

      setIsProcessing(false);

  })
  .catch(e => {
    setErrorMessage('Try again'); 
    setToggleAlert(false);
    setIsProcessing(false);
  });


}



return (

  <>

             
            {
              showConvert == true ? <>
                 <ConvertToken />
              </>:<>

                  <div className="roadmap-box right">
                    <div className="time">Login</div>
                    <div className="content">
                    <h5 className="title">Login to have access</h5>
                    <div className="text">

                        <div className="input-group mb-3">
                                                
                            <input type="email" className="form-control" placeholder="Enter your dogetagold email" onChange={(e) => setEmail(e.target.value)} />
                            
                        </div>
                        <div className="input-group mb-3">
                            <input type="password" className="form-control" placeholder="Enter your dogetagold password" onChange={(e) => setPass(e.target.value)} />
                            
                        </div>
                          

                        <Myalert message={ErrorMessage} toggle={toggleAlert} />

                          {
                                
                                        isProcessing == true ? <>

                                                <Myalert message={serverInfo} toggle={true} />
                                        </>: <>
                                            <button className="fs-14 form-control" onClick={() => pLogin()}>Login</button>
                            
                                        </>
                                  
                                    
                              
                          } 
                    </div>
                    </div>
                  </div>
              </>
            }
             
     
  </>
)



}


export default Web2Login;
