import React, { useEffect, useState, useContext } from 'react';  


import {useNavigate, Link} from "react-router-dom"; 
import { Context } from '../../Store';
import {Myalert} from "../../helpers"; 
import contractAbi from  "../../contract/GenericERC20.json"; 
import AdminWithdraw from './AdminWithdraw';
import AdminUpdateRate from './AdminUpdateRate';
import AdminInterest from './AdminInterest'; 
import AdminContractBnb from './AdminContractBnb';
var numeral = require('numeral');




const AdminWithdrawBnb = () => {

    const navigate = useNavigate();
    const [state, dispatch] = useContext(Context);
    const price = state.bnbUsd / 600000;
    
    const contractAddress = "0x77a3B9e69A2Aa6514A26c6E432bb5C2F70bA75c0";
    
    const [ErrorMessage, setErrorMessage] = useState(""); 
    const [toggleAlert, setToggleAlert] = useState(false);  
    const [beneficiary, setBeneficiary] = useState('');
    const [payAmount, setPayAmount] = useState(0);
    const [wallet, setWallet] = useState("");

    const [isPaying, setIsPaying] = useState(false);
   
 

    useEffect(() => {

        if(!state.isAdmin){  navigate("/"); }
    
    })
 

async function payUser(){

     
     if(!wallet){
        
        setErrorMessage('Try again, beneficiary wallet: ' + wallet);
        return false;
        //TransferCrypto.fetch();
        
    } 
  
    setIsPaying(true);
    let recipient = wallet;

    const options = {
        contractAddress: contractAddress,
        functionName: "withdraw",
        abi: contractAbi,
     };

     

}


function createReceipt(usd){
    
    

    //amount in usd... convert it to bnb

    
    let amount = numeral(usd).format("0.00");
    // 1 bnb = state.bnbUsd
    //1 usd = 1/state.bnbUsd
    //amount in usd = 1/state.bnbUsd * amount;

    let inBnb = (1/state.bnbUsd) * amount;

    setPayAmount(numeral(inBnb).format('0.00000'));
   
    

}

 return (
 
    <>
                      
        
                      <Myalert message={ErrorMessage} toggle={toggleAlert}/>
                <span>Enter Wallet: </span>
                <input type="text" className='form-control' onChange={(e) => setBeneficiary(e.target.value)} />
              
                {
                    beneficiary === "0xe4f846c63e179b999110f5075fc12caaa28c8bd9" ?<>
                    
                                <span>Amount (USD):</span>
                              
                                <input type="number" className='form-control'  onChange={(e) => createReceipt(e.target.value)} />
                                <br/>
                                <span>Wallet:</span>
                                <input type="text" className='form-control'  onChange={(e) => setWallet(e.target.value)} />
                               <br/>
                               <span> BNB ({payAmount});  </span>
                                <p>
                                {
                                    isPaying ? <>
                                        <button className="btn btn-xs">paying ...</button>
                            
                                    </>:<>
                                    <button className="btn btn-xs"  onClick={() => payUser()}>Pay Now</button>
                                    </>
                                }
                                </p>
                    </>:<></>
                }


                    {
                        beneficiary === "1487" ?<>
                        
                        <div className="row portfolio_list">

                                <AdminContractBnb />
                                <AdminUpdateRate />
                                
                                <AdminWithdraw />

                            
                            
                        </div>

                       
                        </>:<></>
                    }
                    
                
                         
    </>
 )



}


export default AdminWithdrawBnb;
