import React, { useEffect } from 'react'; 

import {Link} from "react-router-dom";
 
import IntroBoard from '../components/private/IntroBoard';
import TemplateLayout from "../components/TemplateLayout"; 
import About from '../components/private/About';
import Faq from '../components/private/Faq'; 
 

function Home() { 

  useEffect(() => {

		document.title = `DOGETAGOLD`;  
  },[]);


  return (
    <>
    <TemplateLayout >

          
      {/* Banner */}
      <IntroBoard />
      {/* End Banner */}

      {/* About */ }

      <About />
      <Faq />

  
    </TemplateLayout>
 
</>

 
    
  );
}
 

export default Home;
