import React  from 'react'; 
import  { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Home from "./pages/Home"; 
import Store from "./Store"; 
import SwapPage from './pages/SwapPage'; 
import SystemPage from './pages/SystemPage'; 
import Staking from './pages/Staking';
import ConvertPage from './pages/ConvertPage';

const App = () => {
   
    
 return (
     <>
     <Store >
            <Router>
                <Routes>
                <Route exact path="/" element={<Home/>}/>  
                    <Route exact path="/swap" element={<SwapPage />} /> 
                    <Route exact path="/mine" element={<Staking />} /> 
                    <Route exact path="/admin" element={<SystemPage/>}/>  
                    <Route exact path="/convert" element={<ConvertPage/>}/> 
                    <Route path="*" element={<Home/>}/>
                    <Route exact path=":ref" element={<Home/>}/>
                    
                </Routes>
            </Router>
     </Store>
      
     </>
 );
}

export default App;
