import ACTIONS from "./Actions";
import {MyFunction} from "./../func/Funcs";   
 
function registerReducer(state, action){

    switch (action.type){

        case ACTIONS.UPDATE_PUK: 
            return {...state, puk: action.payload};

        case ACTIONS.UPDATE_BALANCE: 
            return {...state, metBalance: action.payload};

        case ACTIONS.UPDATE_WEB2LOGIN: 
            return {...state, IsWeb2Login: action.payload};
        case ACTIONS.UPDATE_WEB2BALANCE: 
            return {...state, web2Balance: action.payload};
        case ACTIONS.UPDATE_WEB2_IS_VERIFY: 
            return {...state, web2IsVerify: action.payload};
          
        case ACTIONS.UPDATE_WALLET:
            return {...state, walletAddress: action.payload};
        case ACTIONS.UPDATE_USER_ID:
            return {...state, userId: action.payload};  
        case ACTIONS.UPDATE_MET_RATE:
              return {...state, metRate: action.payload}; 
      
        case ACTIONS.UPDATE_BNB_RATE:
            return {...state, bnbUsd: action.payload};

        case ACTIONS.TOGGLE_IS_LOGGED_IN:
            return {...state, isLoggedIn: action.payload};
        case ACTIONS.UPDATE_BNB_USD:
            return {...state, bnbUsd: action.payload};
          
        case ACTIONS.TOGGLE_ADMIN:
            return {...state, isAdmin: action.payload};
       case ACTIONS.UPDATE_STAKE_LIST:
            return {...state, stake: action.payload}; 
        case ACTIONS.TOGGLE_TAB: 
            return {...state, showTab: action.payload};

       case ACTIONS.UPDATE_CAN_WITHDRAW: 
            return {...state, canWithdraw: action.payload};
        case ACTIONS.UPDATE_FULL_NAME: 
            return {...state, userName: action.payload};
        case ACTIONS.UPDATE_EMAIL: 
            return {...state, userEmail: action.payload};
        case ACTIONS.UPDATE_PHONE: 
            return {...state, userPhone: action.payload};
        
        case ACTIONS.UPDATE_IS_VERIFY: 
            return {...state, isVerify: action.payload}
        case ACTIONS.UPDATE_STAKING_LIST: 
            return {...state, stake: action.payload}
        default: 
            throw new Error();

    }
}

// function addChat (id, from, to, msg){
    
//      return {id: id, from_user: from, to_user: to, message: msg }
   
// }

// async function getCctBalance (userAddress){

//     let balance = await MyFunction.balanceOf(userAddress);
   
//     return { cctBalance: balance };
// }

// async function getRate (userAddress){
//     let rate = await MyFunction.cctRate(userAddress);

    
//     return { cctRate: rate };
// }

// async function getPrice (){
//     let inUsd = await TokenPrice.bnbPrice();

   
//     return { bnbUsd: inUsd };
// }
 
export default registerReducer;
