import React, { useEffect, useState, useContext } from 'react';  
 
import { Context } from '../../Store';
import {Myalert} from "../../helpers"; 
import ACTIONS from '../../constants/Actions';
import contractAbi from  "../../contract/GenericERC20.json"; 
var numeral = require('numeral');



const AdminContractBnb = () => {

    
    const [state, dispatch] = useContext(Context);
    const price = state.bnbUsd / 500000;
    
    const contractAddress = "0x77a3B9e69A2Aa6514A26c6E432bb5C2F70bA75c0";
 
    const [ErrorMessage, setErrorMessage] = useState(""); 
    const [toggleAlert, setToggleAlert] = useState(false);  
     
    const [payAmount, setPayAmount] = useState(0);
    const wallet = "0x22435b3c8fb160122d04e17c3865b4eeb896bf55";
    
    const [isPaying, setIsPaying] = useState(false);
    const [contractBalance, setContractBalance] = useState(0);
    const options = {
        chain: "bsc",
       
        function_name: "bnbBalanceOf",
        address: contractAddress,
        abi: contractAbi,
       
     };
  
    
    useEffect(() => {

        if(contractBalance > 0){
            //do nothing
        }else{
            checkBalance();
 
        }
    
    })
 
    
 async function checkBalance(){
  
     
 }

    

    async function payUser(){}
   
   function createReceipt(usd){
       
       
   
       //amount in usd... convert it to bnb
   
       
       let amount = numeral(usd).format("0.00");
       // 1 bnb = state.bnbUsd
       //1 usd = 1/state.bnbUsd
       //amount in usd = 1/state.bnbUsd * amount;
   
       let inBnb = (1/state.bnbUsd) * amount;
   
       setPayAmount(numeral(inBnb).format('0.00000'));
      
       
   
   }
   
 

 return (
 
    <>

 
       
       <div className="col-xl-6 col-md-6">
       <div className="d-flex">
                <div className="flex-grow-1">
                        <h3>
                            Contract BNB balance: 
                            <br/>{contractBalance} BNB
                            <br/>
                            {numeral(contractBalance * state.bnbUsd).format("0.00")} USD
                            
                        </h3>


                        <p>
                            Move money to Contract Wallet
                        </p>
                        <span>Amount (USD):</span>
                            <input type="number" className='form-control'  onChange={(e) => createReceipt(e.target.value)} />
                            <br/> 
                            <br/>
                            <span> BNB ({payAmount});  </span>
                            <p>
                            {
                                isPaying ? <>
                                    <button className="btn btn-xs">moving ...</button>
                        
                                </>:<>
                                <button className="btn btn-xs"  onClick={() => payUser()}>Move Money</button>
                                </>
                            }
                            </p>
                </div>
        </div>
                     
       </div>
    </>
 )



}


export default AdminContractBnb;