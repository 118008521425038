import React, { useEffect, useState, useContext } from 'react'; 
 
import Header from '../components/private/Header';
import {useNavigate, Link} from "react-router-dom"; 
import contractAbi from  "../contract/GenericERC20.json";
import {Myalert} from "../helpers"; 
import { Context } from '../Store';
import ACTIONS from "../constants/Actions";
import {MyFunction} from "../func/Funcs";  
import TemplateLayout from '../components/TemplateLayout';
import StakeBasic from '../components/private/StakeBasic';
import StakeGold from '../components/private/StakeGold';
import StakePremium from '../components/private/StakePremium';
import StakePro from '../components/private/StakePro';
import RunningMining from '../components/private/RunningMining';
var numeral = require('numeral');


const Staking = () => {

    const navigate = useNavigate();
    const [state, dispatch] = useContext(Context); 
    //const { switchNetwork, chainId } = useChain(); 
    const [amount, setAmount] = useState(1);
    
    
     

    useEffect(() => {

      document.title = `Mine Dogetagold Token`;   
       
   },[]);

 

 

 return (
 
    <>
  
  <TemplateLayout >



<section className="roadmap">
  <div className="container">
    <div className="row">
      <div className="col-12">
        <div className="block-text center">
          <h6 className="sub-heading" >
            <span style={{display: "block"}}>  Stake MET</span>
             

            
          </h6>
          
           
          <p>
            {
              state.metBalance > 0? <>
                <span>Balance: </span><br/>
                <span>DGT: {numeral(state.metBalance).format("0.00")}</span>
              </>:<></>
            }
            
          </p>

         
        </div>
        <div className="roadmap__main">
           
           <RunningMining /> 
           <StakeBasic /> 
           {/* <StakePremium /> 
           <StakePro />  */}
          
        
          
          
          
        </div>
      </div>
    </div>
  </div>
</section>


   


</TemplateLayout>

      
       
    </>
 )



}


export default Staking;
