 
const initialState = 
    {
        puk: 0,
        userId: 0, 
        isVerify: false,
        walletAddress: '', 
        userName: "",
        userEmail: "",
        userPhone: "",
        metBalance: 0, 
        isAdmin: false,
        isLoggedIn: false,
        canWithdraw: false, 
        refBalance: 0,
        metRate: 166663,
        bnbRate: 0,
        showTab: true,
        stake: {},  
        bnbUsd: 0,
        tokenContract: "0xB60558C63ccbC0073D39B11B745D5bc64a38c36C",  
        tokenName: "DOGETAGOLD TOKEN",
        tokenSymbol: "DGT",
        totalSupply: "10,000,000,000",
        IsWeb2Login: false,
        web2Balance: 0,
        web2IsVerify: false,
    };
 

export default initialState;
