import React, { useEffect, useState, useContext } from 'react';  
  
//var numeral = require('numeral');  
import {useNavigate, Link} from "react-router-dom"; 
import { Context } from '../../Store';
import {Myalert} from "../../helpers";
import {urllink} from "../../constants" ;
var numeral = require('numeral');



const AdminWithdraw = () => {

    const navigate = useNavigate();
    const [state, dispatch] = useContext(Context);
    const price = state.bnbUsd / 500000;
    
    const contractAddress = "0x77a3B9e69A2Aa6514A26c6E432bb5C2F70bA75c0";
    
    const [ErrorMessage, setErrorMessage] = useState(""); 
    const [toggleAlert, setToggleAlert] = useState(false); 
	const [withdrawList, setWithdrawList] = useState([]);
    const [fetchingWithdraw, setFetchWithdraw] = useState(false);
    const [beneficiary, setBeneficiary] = useState('');
    const [payAmount, setPayAmount] = useState(0);
    
    const [userDetails, setUserDetails] = useState([]);
    
    const [showReceipt, setShowReceipt] = useState(false);
    const [hasPaid, setHasPaid] = useState(true);
    const [prevHash, setPrevHash] = useState('');

   
 

    useEffect(() => {

        if(!state.isAdmin){  navigate("/"); }

        
    })

async function payment(data) {}
  

async function getWithdrawList(){

    const data = {
  
       
        puk: state.puk,
        uri: urllink.withdrawList,


    }
    // setFetchWithdraw(true);

    // CbtApi.sendRequest(data).then((res) => {

        
    //     setWithdrawList(res.withdraw_list);
    //     setErrorMessage(res.message);
    //     setToggleAlert(true);
    //     setFetchWithdraw(false);
      

    // }).catch(e => {

     
    //     setErrorMessage(e.message);
    //     setToggleAlert(true);
    //     setFetchWithdraw(false);


    // })
 
}


async function payUser(){

     
     if(beneficiary){
        setFetchWithdraw(true);
        setHasPaid(false);
       
        
    }else{
        
        setErrorMessage('Try again');
        setToggleAlert(true);
        
        
    }
  


}


function createReceipt(data) {}

 return (
 
    <>
                      

                   
                    {
                        showReceipt === true ? <>
                            <div className='col-xl-12 col-md-12'>
                                
                                   
                                        <h4>Payment Receipt</h4>
                                   

                                    <hr/>
                                    <span>Wallet: {beneficiary}</span>
                                    <br/>
                                    <span>Amount (bnb):  {payAmount}</span>
                                    <p>
                                    <button className="btn btn-xs"  onClick={() => payUser()}>Pay Now</button>
                                

                                    </p>
                                   
                            </div>
                        </>:<></>
                         
                    }
                     <div className="col-xl-12 col-md-12">
                        <Myalert message={ErrorMessage} toggle={toggleAlert} /> 
                        <div className="card">
                                <div className="card-header border-0">
                                    <h4>Pending Withdraw</h4>
                                    <span className='mute'>
                                        {
                                            fetchingWithdraw ? <>
                                                ... connecting to server 
                                                <em  className="fa fa-refresh fa-spin   fa-fw"> </em>
                                            </>:<>
                                            <button className='btn btn-xs' onClick={() => getWithdrawList()}>Refresh</button>
                                            </>
                                        }
                                        
                                    </span> 
                                </div>
                                <div className="card-body pt-0">
                                                                
                                    <div className="transaction-table">
                                        <div className="table-responsive">
                                            <table className="table mb-0 table-responsive-sm">
                                                <tbody>
                                                
                                                    {
                                                    
                                                        withdrawList ? 
                                                            withdrawList.map(((item, index) => (

                                                            <tr key={index}>
                                                                <td><span className="sold-thumb"><i className="la la-arrow-down"></i></span>
                                                                </td> 
                                                                <td>
                                                                {item.date}
                                                                </td>
                                                                
                                                                <td className="text-danger">{item.user_wallet}</td>
                                                                <td>{numeral(item.amount * price).format("0.0000")} USD</td>
                                                                <td>
                                                                    <button className='btn btn-xs' onClick={() => createReceipt(item)} >Create Receipt</button>

                                                                </td>
                                                                 
                                                            </tr>
                                                        ))): <></>
                                                    }
                                                
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                                            
                                </div>
                                
                                 
                        </div>
                    </div>
    </>
 )



}


export default AdminWithdraw;
