import React, { useEffect, useState, useContext } from 'react'; 
import {useNavigate, Link} from "react-router-dom";
import { Contract, ContractInterface, Signer, ethers } from "ethers";
import contractAbi from "../../contract/GenericERC20.json";
import {Myalert} from "../../helpers"; 
import { Context } from '../../Store';
import ACTIONS from "../../constants/Actions";
import {MyFunction} from "../../func/Funcs";   
import { useWeb3React } from "@web3-react/core";  


var numeral = require('numeral');



const RunningMining = () => {

   
  const navigate = useNavigate();
  const [state, dispatch] = useContext(Context);
  const contractAddress = state.tokenContract;
  //const { switchNetwork, chainId } = useChain();
  
  
  const userAddress = state.walletAddress;
   
  const [metToStake, setMetToStake] = useState(0);
  const sellCharge = 0.003;
  
  const [metToGet, setMetToGet] = useState(0);
   
  const[ ErrorMessage, setErrorMessage] = useState(""); 
  const [toggleAlert, setToggleAlert] = useState(false); 
  
  const [showButton, setShowButton] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);
  const [serverInfo, setServerInfo] = useState("");
  const [serverSellInfo, setServerSellInfo] = useState(""); 
  
  const [refBonus, setRefBonus] = useState(0);
  const { account, provider } = useWeb3React();
  const bnbValue = state.metBalance / state.metRate;

 



function calculateSell(inputAmount){
setToggleAlert(false);

if(inputAmount >= 1){

  setMetToStake(inputAmount)
    setShowButton(true);
}else{
    setShowButton(false);
}

let met = (0.4/100) * inputAmount * 60; // 1% daily multiply by amount to stake then multiply by total days
setMetToStake(inputAmount);
setMetToGet(met);

if(inputAmount > state.metBalance){

  setShowButton(false);
  setErrorMessage('Your met balance is low');
  setToggleAlert(true);
  return false;

}


setToggleAlert(false);


}

async function updateBalance(){

  let newBal = await MyFunction.balanceOf(userAddress);
  dispatch({type: ACTIONS.UPDATE_BALANCE, payload: newBal});
  
}


async function stake(){


if(metToStake < 1){
  
    setErrorMessage('Minimum of 1 MET is allowed');
    setToggleAlert(true);
    return false;

}

setToggleAlert(false); 
setIsProcessing(true);
setServerInfo('...connnecting to wallet')




let metAmount = ethers.utils.parseEther(metToStake);

  
const signer = provider.getSigner();
const contract = new ethers.Contract(state.tokenContract, contractAbi, signer );
const overrides = {value: ethers.utils.parseEther("0.003")} 

dispatch({type: ACTIONS.TOGGLE_TAB, payload: false});

try {

  const tx = await contract.stake(metToStake,overrides); 
  // Waiting for the transaction to be mined
  const receipt = await tx.wait();
      
  if(receipt.status == 1){  
    setIsProcessing(false);
    dispatch({type: ACTIONS.TOGGLE_TAB, payload: true}); 

    //login to database and save the stake

  }else{
     setErrorMessage('Sorry, unable to process at the moment');
      setToggleAlert(true);

      dispatch({type: ACTIONS.TOGGLE_TAB, payload: false});
      setIsProcessing(false);
  }


  updateBalance();   


}
catch(err){

setErrorMessage("Try again.");
console.log(err);

setToggleAlert(true);
setIsProcessing(false);

}

            
}



return (

  <>

              <div className="roadmap-box ">
                <div className="time">Mining</div>
                <div className="content">
                <h5 className="title">Capital: </h5>
                <div className="text">

                     
                    <div className='input-group mb-3'>
                      <p>
                        Token Earn: { numeral(metToGet).format("0.0000") } GDT
                      </p>
                        
                        
                    </div>
                     
                    <div className='input-group mb-3'>
                      <p className='mute'>
                           Running Days: 
                      </p>
                    </div>
                  


                    <Myalert message={ErrorMessage} toggle={toggleAlert} />

                      {
                            showButton == true ? <>

                                {
                                    isProcessing == true ? <>

                                            <Myalert message={serverInfo} toggle={true} />
                                    </>: <>
                                        <button className="fs-14 form-control" onClick={() => stake()}>Mine Token Profit</button>
                        
                                    </>
                                }
                                
                            </>: <> </>
                      } 
                </div>
                </div>
              </div>
     
  </>
)


}


export default RunningMining;
