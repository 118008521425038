import React, { useEffect, useState, useContext } from 'react'; 
import {useNavigate, Link} from "react-router-dom"; 
import { Context } from '../Store'; 
import AdminWithdrawBnb from '../components/private/AdminWithdrawBnb';
import AdminUpdateRate from '../components/private/AdminUpdateRate';
import TemplateLayout from '../components/TemplateLayout';



const SystemPage = () => {

    const navigate = useNavigate();
    const [state, dispatch] = useContext(Context); 
    
    
   
 

    useEffect(() => {

        if(!state.isAdmin){  navigate("/"); }

         
    })

  

 return (
 
    <>
    <TemplateLayout>
      <div className='row'>
         <div className='col-md-12'> 
         
         <AdminWithdrawBnb />
         </div>
      </div>
      
    </TemplateLayout>
     
    </>
 )



}


export default SystemPage;
