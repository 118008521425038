import React from "react";  
import { Alert } from "react-bootstrap";
 

import {Auth} from "./Auth"; 
const Myalert = (props) => {

    return    (
        <>

                {
                    props.toggle ?  <>
                
                    <Alert>
                      <h6  className="sub-heading" style={{color:'black'}} >
                        {props.message}
                      </h6>
                    </Alert>
                    
                    </>: <>
                    
                    
                    </>
                }
                
            </>
    )  
        
    
    

}


 
export {
     
    Auth,
    Myalert
     
     
}
