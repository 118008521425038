 

const ACTIONS = {

    UPDATE_STAKING_LIST: "update-staking-list",
    UPDATE_MET_RATE: 'update-met-price',
    UPDATE_BALANCE: 'update-balance',
    UPDATE_IS_ADMIN: 'update-is-admin',
    UPDATE_USER_ID: "update-user-id", 
    UPDATE_PUK: 'update-puk', 
    UPDATE_WALLET: 'update-wallet',
    UPDATE_BNB_RATE: 'update-bnb-rate',
    UPDATE_FULL_NAME: 'update_full_name',
    UPDATE_EMAIL: 'update_email',
    UPDATE_PHONE: 'update_phone',
    UPDATE_IS_VERIFY: 'update_is_verify',
    
    TOGGLE_ADMIN:  'toggle-admin',
    TOGGLE_IS_LOGGED_IN: 'toggle-is-logged-in',
    TOGGLE_TAB: 'toggle-tab',
    UPDATE_IS_LOG_IN: 'update-is-log-in', 
    UPDATE_BNB_USD : 'update-bnb-usd',
    UPDATE_CAN_WITHDRAW: 'update-can-withdraw',

    SWAP_TO_MET: 'swapToMet',
    SWAP_FROM_MET: 'swapFromMet',
    STAKE: 'stake',
    STAKE_REWARD: 'stakeReward',
    UPDATE_WEB2LOGIN: 'update-web2-login',
    UPDATE_WEB2BALANCE: 'update-web2-balance',
    UPDATE_WEB2_IS_VERIFY: 'update-web2-is-verify',
    
    
}
 

 

export default ACTIONS;
