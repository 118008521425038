import React, { useEffect, useState, useContext } from 'react';  
  
//var numeral = require('numeral');   
import { Context } from '../../Store';
import {Myalert} from "../../helpers"; 
import ACTIONS from '../../constants/Actions';
import contractAbi from  "../../contract/GenericERC20.json"; 
var numeral = require('numeral');



const AdminUpdateRate = () => {

    
    const [state, dispatch] = useContext(Context);
     
    const [newRate, setNewRate] = useState(0);
    const contractAddress = "0x77a3B9e69A2Aa6514A26c6E432bb5C2F70bA75c0";
    const  cctPrice = state.bnbUsd / state.cctRate; 
    const [ErrorMessage, setErrorMessage] = useState(""); 
    const [toggleAlert, setToggleAlert] = useState(false); 
	const [isProcessing, setIsProcessing] = useState(false); 
    
    
    


 async function updateRate() {}
 

 return (
 
    <>

 
       <div className="col-xl-6 col-md-6">
       <Myalert message={ErrorMessage} toggle={toggleAlert}/> 
                            <div className="d-flex">
                                <div className="flex-grow-1">
                                    <h4>Update Rate</h4>
                                    <p>Current Rate: <br/>1 BNB = {state.cctRate} Cct
                                        <br/>
                                        1 Cct = { numeral(cctPrice).format("0.000000") } USD
                                    </p> 
                                    <div className="input-group mb-3">
                                                                    
                                    <input type="number" className="form-control" placeholder="500000"   onChange={(e) => setNewRate(e.target.value)} />
                                                                
                                    </div>

                                    {

                                        isProcessing ? <>

                                            <button className='btn btn-xs btn-success' >...updating</button>
                                        </>:<>
                                        <button className='btn btn-xs btn-primary' onClick={() => updateRate()} >Update</button>
                                    
                                        </>
                                    }

                                
                                </div>
                            </div>
                        </div>
    </>
 )



}


export default AdminUpdateRate;
