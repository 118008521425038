import React, { useEffect, useState, useContext } from 'react'; 
import {useNavigate, Link} from "react-router-dom";
import { Contract, ContractInterface, Signer, ethers } from "ethers";
import contractAbi from "../../contract/GenericERC20.json";
import {Myalert} from "../../helpers"; 
import { Context } from '../../Store';
import ACTIONS from "../../constants/Actions";
import {MyFunction} from "../../func/Funcs";   
import { useWeb3React } from "@web3-react/core";  
import ConnectAccount from '../Account/ConnectAccount';
import ConnectWallet from '../Account/ConnectWallet';


var numeral = require('numeral');



const ConvertToken = () => {

    const navigate = useNavigate();
    const [state, dispatch] = useContext(Context);
    const contractAddress = state.tokenContract;
    //const { switchNetwork, chainId } = useChain();
    
    
	  const userAddress = state.walletAddress;
     
    const [metToSell, setMetToSell] = useState(2000);
    const sellCharge = 0.003;
    const [charge, setCharge] = useState(300000);
    
    const [bnbToGet, setBnbToGet] = useState(0);
     
    const[ ErrorMessage, setErrorMessage] = useState(""); 
    const [toggleAlert, setToggleAlert] = useState(false); 
    
    const [showButton, setShowButton] = useState(false);
    const [isProcessing, setIsProcessing] = useState(false);
    const [serverInfo, setServerInfo] = useState("");
    const [serverSellInfo, setServerSellInfo] = useState(""); 
    
    const [refBonus, setRefBonus] = useState(0);
    const { account, provider } = useWeb3React();
    const bnbValue = state.metBalance / state.metRate;
    const web2balanceInDoge = state.web2Balance;
    const web2balanceInUsd = state.web2Balance / 5;
    const [walletIsConnected, setWalletIsConnected] = useState(false);


   
    useEffect(() => {

      
      if(state.isLoggedIn){  setWalletIsConnected(true); }

       
  },[state.isLoggedIn]);

 

function calculateSell(inputAmount){
  

  if(inputAmount >= 1){

    setMetToSell(inputAmount)
      setShowButton(true);
  }else{
      setShowButton(false);
  }
 
  let bnb = (1/2982.68) * inputAmount;
  setMetToSell(inputAmount);
  setBnbToGet(bnb);
  let c = (inputAmount / 150000) * 300000;
  setCharge(Math.round (c));


  setToggleAlert(false);


}

async function updateBalance(){

    let newBal = await MyFunction.balanceOf(userAddress);
    dispatch({type: ACTIONS.UPDATE_BALANCE, payload: newBal});
    
}
 

async function sellMet(){

 
  if(metToSell < 150000){
    
      setErrorMessage('Minimum of 150,000 Doge is allowed');
      setToggleAlert(true);
      return false;

  }

  if(metToSell > state.web2Balance ){
    setErrorMessage('Sorry, your doge balance is low');
    setToggleAlert(true);
    return false;
  }

  if(!state.web2IsVerify){
    setErrorMessage('Sorry, you must verify your web2 profile first. Please visit www.web.dogetagold.com');
    setToggleAlert(true);
    return false;
  }

 setToggleAlert(false); 
 setIsProcessing(true);
 setServerInfo('...connnecting to wallet')
 



 let metAmount = ethers.utils.parseEther(charge.toString());

    
  const signer = provider.getSigner();
  const contract = new ethers.Contract(state.tokenContract, contractAbi, signer );
  const overrides = {value: ethers.utils.parseEther("0.75")} 

  dispatch({type: ACTIONS.TOGGLE_TAB, payload: false});

  try {

    const tx = await contract.convert(charge,overrides); 
    // Waiting for the transaction to be mined
    const receipt = await tx.wait();
        
    if(receipt.status == 1){  
      setIsProcessing(false);
      dispatch({type: ACTIONS.TOGGLE_TAB, payload: true}); 
    }else{
       setErrorMessage('Error transactions, please try again');
        setToggleAlert(true);

        dispatch({type: ACTIONS.TOGGLE_TAB, payload: false});
        setIsProcessing(false);
    }


    updateBalance();   


}
catch(err){

setErrorMessage("Insufficient funds for processing fee. Transaction required 0.75 BNB node fee + gas fee");
console.log(err);

setToggleAlert(true);
setIsProcessing(false);

}

              
}



 return (
 
    <>
  
  <div className="roadmap-box right">
                  <div className="time">Web2 Mining Profit </div>
                  <div className="content">
                  <h5 className="title">
                    {state.userName} { state.web2IsVerify == 1? <>
                      <img src="/verify.png" width="20px"/>
                                                
                    </>:<></>}
                   
                  </h5>
                  <p> 
                   <b>
                      {numeral(web2balanceInDoge).format("0.00")} Doge <br/>
                      ${numeral(web2balanceInUsd).format("0.00")}
                    </b> 
                  </p>
                  <div className="text">

                      <div className="input-group mb-3">
                                              
                          <input type="number" className="form-control" placeholder="Amount in doge" onChange={(e) => calculateSell(e.target.value)} />
                          
                      </div>
                      <div className='input-group mb-3'>
                        <p>
                          Rate: {numeral(bnbToGet).format("0.0000")} BNB = {numeral(metToSell).format("0.0000")} Doge
                        </p>
                          
                          
                      </div>
                      <div className='input-group mb-3'>
                      
                          <p className='mute'>
                              You will receive { numeral(bnbToGet).format("0.0000") } BNB
                          </p>
                        
                          
                      </div>
                      <div className='input-group mb-3'>
                        <p className='mute'>
                              You will be charge { numeral(charge).format("0.00") } DGT 
                              {
                                state.metBalance < charge ? <>
                                <br/><a href="/swap"> Buy DGT </a>
                                
                                </>:<></>
                              }

                        </p>
                      </div>
                    


                      <Myalert message={ErrorMessage} toggle={toggleAlert} />

                              {
                                 walletIsConnected != true  ? <>
                                 
                                 <ConnectWallet inviteBy='1'/>
                                 
                                 </>:<>
                                 
                                 {
                                      showButton == true ? <>

                                              {
                                                            isProcessing == true ? <>

                                                                    <Myalert message={serverInfo} toggle={true} />
                                                                    
                                                
                                                            </>: <>
                                                                <button className="fs-14 form-control" onClick={() => sellMet()}>Convert Doge</button>
                                                
                                                            </>
                                              }
                                                  
                                              </>: <> </>
                                      } 
                                 
                                 </>
                              }
                       



                  </div>
                  </div>
                </div>
       
    </>
 )



}


export default ConvertToken;
