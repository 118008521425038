import React, { useEffect, useState } from 'react'; 
import { Link } from "react-router-dom";

const Faq = (props) => {

 

 return (
 
    <>


<section className="faq s3">
  <div className="shape" />
  <div className="container">
    <div className="row">
      <div className="col-xl-6 col-md-12">
        <div className="block-text">
           
          <h3 className="heading">
          Dive into Dogetagold Token
          </h3>
          
        </div>
        <div className="faq__main flat-tabs">
          <ul className="menu-tab">
             
            <li className="active">
              {" "}
              <h6 className="fs-14">Dogetagold</h6>
            </li>
            
          </ul>
          <div className="content-tab">
            <div className="content-inner">
              <div className="flat-accordion">
                <div className="flat-toggle">
                  <h6 className="toggle-title">
                    <span>01.</span> Mining Sharing {" "}
                    <span className="icon-plus" />
                  </h6>
                  <div className="toggle-content">
                    <p>
                     
Dogetagold Token gives access to your lost crypto minings. With dogetagold, you withdraw your dormant crypto, bypass government wallet activation regulation.

                    </p>
                  </div>
                </div>
                <div className="flat-toggle">
                  <h6 className="toggle-title">
                    <span>02.</span> Withdraw dormant crypto
                    <span className="icon-plus" />
                  </h6>
                  <div className="toggle-content">
                    <p>
                    Easy to convert crypto mining from dormant to active
                    </p>
                  </div>
                </div>
                <div className="flat-toggle">
                  <h6 className="toggle-title">
                    <span>03.</span> Stake and earn more
                    <span className="icon-plus" />
                  </h6>
                  <div className="toggle-content">
                    <p>
                    Stake Dogetagold Token and earn more back in no time
                    </p>
                  </div>
                </div>
                <div className="flat-toggle">
                  <h6 className="toggle-title">
                    <span>04.</span>Bypass Regulation
                    <span className="icon-plus" />
                  </h6>
                  <div className="toggle-content">
                    <p>
                    Bypass all government regulations, withdraw your crypto with Dogetagold Token in 10 seconds
                    </p>
                  </div>
                </div>
              </div>
            </div>
             
          </div>
        </div>
      </div>
      <div className="col-xl-6 col-md-12">
        <div className="image">
          <img src="assets/images/layouts/img-faq.png" alt="" />
        </div>
      </div>
    </div>
  </div>
</section>

  
 

    </>
 )



}


export default Faq;
