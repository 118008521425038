import React, {useState, useContext } from 'react';    
import {Myalert} from "../../helpers";
import {urllink} from "../../constants" ;  

import { Context } from '../../Store';

const AdminInterest = () => {

    const [state, dispatch] = useContext(Context); 
    const [ErrorMessage, setErrorMessage] = useState(""); 
    const [toggleAlert, setToggleAlert] = useState(false);  
    const [amount, setAmount] = useState(0);
    const [isAddingInterest, setIsAddingInterest] = useState(false);
    
    
    

 

 

async function addinterest(){

    const data = {
  
        amount: amount,
        puk: state.puk,
        uri: urllink.addInterest,


    }
    // setIsAddingInterest(true);
    // CbtApi.sendRequest(data).then((res) => {



       
    //     setErrorMessage(res.message);
    //     setToggleAlert(true);
    //     setIsAddingInterest(false);

    // }).catch(e => {

    //     setIsAddingInterest(false);
    //     setErrorMessage(e.message);
    //     setToggleAlert(true);
      


    // })




}
 

 return (
 
    <>
            
            <div className="col-xl-6 col-md-6">
                <Myalert message={ErrorMessage} toggle={toggleAlert}/> 
                <div className="d-flex">
                    <div className="flex-grow-1">
                        <h4>Add Cbt Interest</h4>
                        <span className='mute'>
                                2 means 2% or 0.02
                        </span> 
                        <div className="input-group mb-3">
                                                        
                        <input type="number" className="form-control" placeholder="2"   onChange={(e) => setAmount(e.target.value)} />
                                                    
                        </div>
                        {
                            isAddingInterest ? <>

                                <button className='btn btn-xs  btn-primary'> ...updating</button>
                    
                            </>: <>
                            
                                <button className='btn btn-xs  btn-primary' onClick={() => addinterest()}>Add Interest</button>
                    
                            </>
                        }
                        <hr />
                        <button className='btn btn-xs'>Show last 10 days interest</button>
                    </div>
                </div>
            </div>
    </>
 )



}


export default AdminInterest;
