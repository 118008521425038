const baseUrl  = "https://web.dogetagold.com/api/app/v1";
//const baseUrl = "http://127.0.0.1:3000/api/app/v1";


const puk = window.localStorage.getItem("@puk");

const login = baseUrl + "/login/"  
const mystaking = baseUrl + "/stake";  //list user staking
const stakeCreate = baseUrl + "/stake/create";  //to create staking
const stakeWithdraw = baseUrl + "/stake/create";  //to create staking




const loginUrl = baseUrl + "/login/"  
const mycbt = baseUrl + "/my_cbt"; 
const createCbt = baseUrl + "/create_cbt"; 
const addInterest = baseUrl + "/add_interest";
const refresh = baseUrl + "/refresh";
const claimProfit = baseUrl + "/claim_cbt_profit";
const withdrawList = baseUrl + "/withdraw_list";
const chat = baseUrl + "/chat";
const mailbox = baseUrl + "/mailbox";
const endchat = baseUrl + "/endchat";
const approveWithdraw = baseUrl + "/approve_withdraw";
const refwithdraw = baseUrl + "/refwithdraw";
const updateProfile = baseUrl + "/profile/update";
const addccte = baseUrl + "/ccte/add";
const addbonus = baseUrl + "/cct/addrefbonus";
const withdawCredit = baseUrl + "/cbt/withdraw_credit";
const ccteConvertToCredit = baseUrl + "/ccte/convert_to_credit"
const walletWithdraw = baseUrl + '/wallet/withdraw';
const verifyAccount = baseUrl + '/verify/account';



 
export default {

    login,
    mystaking,
    stakeCreate,
    stakeWithdraw,

    loginUrl,
    baseUrl, 
    puk,
    mycbt,
    createCbt,
    addInterest,
    refresh,
    claimProfit,
    withdrawList,
    chat,
    mailbox,
    endchat,
    approveWithdraw,
    refwithdraw,
    updateProfile,
    addccte,
    addbonus,
    withdawCredit,
    ccteConvertToCredit,
    walletWithdraw
 
    
}
